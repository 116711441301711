import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Chrome, Colorful } from "@uiw/react-color";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
} from "@chakra-ui/react";
import { CancelButton } from "../../../../components/Shared/CancelButton";
import { SaveButton } from "../../../../components/Shared/SaveButton";
import ToggleSwitch from "../../../../components/Shared/ToggleSwitch";
import { useVenue } from "../../../../context/VenueContext";
import { isEmptyStr } from "../../../../util/stringHelper";
import { useAdminApiClient } from "../../../../hooks/useApiClient";
import { useToast } from "../../../../hooks/useToast";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import { ErrorMessage } from "../../../../components/ErrorMessage/ErrorMessage";

const FreewayLanguage = () => {
  const { venue, loadVenue } = useVenue();
  const apiClient = useAdminApiClient();
  const { showSuccessToast, showErrorToast } = useToast();

  const [form, setForm] = useState<any[]>([]);
  const [allLanguages, setAllLanguages] = useState<any[]>([]);
  const [formBuffer, setFormBuffer] = useState<any[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [queryString, setQueryString] = useState("");
  const intervalRef = useRef<any>();

  const updateForm = (data: any) => {
    setForm(data);
  };

  const toggleLang = useCallback(
    (lang) => {
      let newFormData = [...form];
      const existingIdx = newFormData.indexOf(lang.slug);
      if (existingIdx > -1) {
        newFormData.splice(existingIdx, 1);
      } else {
        newFormData.push(lang.slug);
      }
      setForm(newFormData);
    },
    [form]
  );

  const initializeForm = () => {
    loadLanguages();
    updateForm([...venue.selectedLanguages]);
    setFormBuffer([...venue.selectedLanguages]);
  };

  const onCancel = () => {
    updateForm([...formBuffer]);
  };

  const loadLanguages = async () => {
    const response = await apiClient.getVenueLanguages(venue._id, true);
    setAllLanguages(response.data?.config?.languages);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = { selectedLanguages: [...form] };
      await apiClient.modifyVenue(venue?._id, payload);
      await loadVenue(false);
      await loadLanguages();
      setIsSubmitting(false);
      showSuccessToast("Languages successfully updated.");
      setFormBuffer([...form]);
      setIsFormChanged(false);
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast("Failed to update languages.");
      console.log(e);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (form.length > 4) {
      isValid = false;
    }

    setIsFormValid(isValid);
  };

  useEffect(() => {
    initializeForm();
  }, [venue]);

  useEffect(() => {
    console.log("form", form);
    validateForm();
    const existingForm = [...formBuffer].sort().join(",");
    const changedForm = [...form].sort().join(",");
    if (existingForm !== changedForm) {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [form]);

  useEffect(() => {
    console.log("allLanguages", allLanguages);
  }, [allLanguages]);

  return (
    <>
      <Text as="h2" className="page-section-title" mb="30px">
        LANGUAGE SETUP
      </Text>
      <Box maxWidth="600px">
        {/* primary color */}
        <Box my={4}>
          <Text className="page-section-content-bold">
            SELECT LANGUAGES
            <br />
            <small>
              Select the 3 languages you would like to enable on Freeway
            </small>
          </Text>
        </Box>

        <Box maxW="400px">
          {allLanguages.length === 0 && (
            <Box>
              <Text style={{ fontSize: 14 }}>Loading...</Text>
            </Box>
          )}
          {allLanguages.length > 0 && (
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              {allLanguages.map((lang) => (
                <GridItem w="100%" key={lang.slug}>
                  <Box>
                    <Checkbox
                      isChecked={form.indexOf(lang.slug) > -1}
                      size="lg"
                      onChange={(e) => {
                        toggleLang(lang);
                      }}
                      title={lang.title}
                    >
                      <Text style={{ fontSize: 14 }}>{lang.title}</Text>
                    </Checkbox>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          )}
        </Box>
      </Box>

      {!isFormValid && (
        <Box mt={3}>
          <ErrorMessage>
            You can choose a maximum of 3 languages for display on Freeway.
            Please remove any additional selections before saving.
          </ErrorMessage>
        </Box>
      )}

      <Box mt="60px">
        <HStack spacing={4}>
          <SaveButton
            isLoading={isSubmitting}
            disable={!isFormValid || isSubmitting}
            onClick={onSubmit}
          />
          {isFormChanged && <CancelButton onClick={onCancel} />}
        </HStack>
      </Box>
    </>
  );
};

export default FreewayLanguage;
