import { Button } from "@chakra-ui/react";
import React from "react";

export const CancelButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      size="sm"
      width="120px"
      height="40px"
      fontSize="14px"
      fontFamily="Oswald"
      fontWeight={500}
      onClick={onClick}
      color="#FFF"
      backgroundColor="#444"
    >
      CANCEL
    </Button>
  );
};
