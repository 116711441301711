import React, { useMemo, useState } from "react";
import { Button, Input, Box, Text, useToast } from "@chakra-ui/react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useFirebase } from "../../context/FirebaseContext";
import { SaveButton } from "../Shared/SaveButton";

interface FileUploadProps {
  existingUrl?: string;
  allowedWidth?: number;
  allowedHeight?: number;
  onComplete: Function;
  accept?: string;
  dimensionError?: string;
  maxFileSize?: number;
}

const FileUpload = ({
  existingUrl,
  allowedWidth,
  allowedHeight,
  onComplete,
  accept,
  dimensionError,
  maxFileSize,
}: FileUploadProps) => {
  const { storage } = useFirebase();
  const [file, setFile] = useState<any>(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const placeholderText = useMemo(() => {
    if (!existingUrl) return "Browse";
    const decodedUrl = decodeURIComponent(existingUrl);
    console.log(decodedUrl, "decodedUrl");
    const name = decodedUrl.substring(
      decodedUrl.lastIndexOf("%2F") + 3,
      decodedUrl.lastIndexOf("?")
    );
    return name;
  }, [existingUrl]);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    console.log("selectedFile", selectedFile);
    if (!selectedFile) return;
    // Check if file is an image
    if (maxFileSize && selectedFile.size / 1000 > maxFileSize) {
      toast({
        title: "Error",
        description:
          dimensionError ||
          `File must be less than ${Math.floor(maxFileSize / 1000)} KB.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Validate dimensions of the image
    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);
    img.onload = () => {
      if (allowedWidth && allowedHeight) {
        if (img.width === allowedWidth && img.height === allowedHeight) {
          setFile(selectedFile);
        } else {
          toast({
            title: "Error",
            description:
              dimensionError ||
              `Image must be exactly ${allowedWidth}x${allowedHeight} pixels.`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setFile(null); // Reset file if dimensions don't match
        }
      } else {
        setFile(selectedFile);
      }
      URL.revokeObjectURL(img.src); // Free up memory
    };
  };

  const handleUpload = () => {
    if (!file) {
      toast({
        title: "No file selected",
        description: "Please choose a file to upload.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsUploading(true);
    const filePath = `uploads/freeway/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      () => {
        // Construct the permanent URL
        const bucketName = "cardit-plus.appspot.com"; // TODO: set as env
        const encodedFilePath = encodeURIComponent(filePath);
        const permanentUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media`;

        setUrl(permanentUrl);
        onComplete(permanentUrl);
        setIsUploading(false);
        console.log("File available at", permanentUrl);
        toast({
          title: "Done",
          description: "File uploaded successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // getDownloadURL(uploadTask.snapshot.ref)
        //   .then((downloadURL) => {
        //     setUrl(downloadURL);
        //     onComplete(downloadURL);
        //     setIsUploading(false);
        //     console.log("File available at", downloadURL);
        //   })
        //   .catch((e) => {
        //     setIsUploading(false);
        //   });
      }
    );
  };

  return (
    <Box py={2} textAlign="center" flexDir="row" display="flex" gap={5}>
      <Box className="theme-file-upload">
        <Text className={file ? "is-selected" : ""}>
          {file ? file.name : placeholderText}
        </Text>
        <Input
          style={{ opacity: 0 }}
          type="file"
          onChange={handleFileChange}
          accept={accept || "image/*"}
        />
      </Box>
      <SaveButton
        title={isUploading ? "UPLOADING..." : "UPLOAD FILE"}
        onClick={handleUpload}
        disable={!file || isUploading}
        isLoading={isUploading}
      ></SaveButton>
      {/* <Text mt={4}>Progress: {progress}%</Text> */}
      {/* {url && (
        <Text mt={4}>
          File uploaded!{" "}
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Text>
      )} */}
    </Box>
  );
};

export default FileUpload;
