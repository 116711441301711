import React from "react";
import { HStack, Switch, Text } from "@chakra-ui/react";

type ToggleSwitchProps = {
  label?: string;
  labelNode?: React.ReactNode;
  isChecked: boolean;
  onChange: (e: any) => void;
  disabled?: boolean;
  customStyle?: React.CSSProperties;
};

const ToggleSwitch = ({
  label,
  labelNode,
  isChecked,
  onChange,
  disabled,
  customStyle,
}: ToggleSwitchProps) => {
  return (
    <HStack justifyContent="space-between" style={customStyle}>
      {labelNode}
      {label && <Text className="page-section-content">{label}</Text>}
      <Switch isChecked={isChecked} onChange={onChange} disabled={disabled} />
    </HStack>
  );
};

export default ToggleSwitch;
