import { Button } from "@chakra-ui/react";
import React from "react";

type SaveButtonProps = {
  title?: String;
  isLoading: boolean;
  disable: boolean;
  width?: any;
  onClick: () => void;
};

export const SaveButton = ({
  title,
  isLoading,
  disable,
  onClick,
  width,
}: SaveButtonProps) => {
  return (
    <Button
      colorScheme="cherryButton"
      size="sm"
      width={width ?? "120px"}
      height="40px"
      fontSize="14px"
      fontFamily="Oswald"
      fontWeight={500}
      isLoading={isLoading}
      disabled={disable}
      onClick={onClick}
    >
      {title ?? "SAVE"}
    </Button>
  );
};
