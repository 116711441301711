import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Chrome, Colorful } from "@uiw/react-color";

import {
  Box,
  Button,
  Divider,
  FormControl,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { CancelButton } from "../../../components/Shared/CancelButton";
import { SaveButton } from "../../../components/Shared/SaveButton";
import ToggleSwitch from "../../../components/Shared/ToggleSwitch";
import { useVenue } from "../../../context/VenueContext";
import { isEmptyStr } from "../../../util/stringHelper";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { useToast } from "../../../hooks/useToast";
import FileUpload from "../../../components/FileUpload/FileUpload";
import FreewayTheme from "./FreewayConfiguration/FreewayTheme";
import FreewayLanguage from "./FreewayConfiguration/FreewayLanguage";

const FreewayConfiguration = () => {
  return (
    <>
      <Text as="h2" className="page-section-title" mb="30px">
        FREEWAY CONFIGURATION
      </Text>

      <Box width="100%" display="flex" flexDirection="row">
        <Tabs
          isLazy
          position="relative"
          variant="unstyled"
          width="100%"
          id="inner-tabs"
          defaultIndex={0}
        >
          <TabList className="inner-tab-list">
            <Tab
              className="inner-tab-button"
              _focus={{ outline: "none" }}
              _selected={{ opacity: 1 }}
            >
              LANGUAGE SETUP
            </Tab>
            <Tab
              className="inner-tab-button"
              _focus={{ outline: "none" }}
              _selected={{ opacity: 1 }}
            >
              THEME SETUP
            </Tab>
            <Tab
              className="inner-tab-button"
              _focus={{ outline: "none" }}
              _selected={{ opacity: 1 }}
            >
              VENUE INFO
            </Tab>
            <Tab
              className="inner-tab-button"
              _focus={{ outline: "none" }}
              _selected={{ opacity: 1 }}
            >
              ADVERTISING
            </Tab>
            <Tab
              className="inner-tab-button"
              _focus={{ outline: "none" }}
              _selected={{ opacity: 1 }}
            >
              CALENDAR
            </Tab>
          </TabList>
          <TabIndicator
            height="4px"
            className="inner-tab-indicator"
            borderRadius="1px"
            width="100%"
          />
          <TabPanels className="inner-tab-panels">
            <TabPanel padding={0}>
              <FreewayLanguage />
            </TabPanel>
            <TabPanel padding={0}>
              <FreewayTheme />
            </TabPanel>
            <TabPanel padding={0}>
              <Text>Coming soon</Text>
            </TabPanel>
            <TabPanel padding={0}>
              <Text>Coming soon</Text>
            </TabPanel>
            <TabPanel padding={0}>
              <Text>Coming soon</Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default FreewayConfiguration;
