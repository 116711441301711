import { Button } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Text } from "@chakra-ui/react";
import React, { useCallback, useMemo, useRef, useState } from "react";

interface PromptState {
  resolve: (result: { ok: boolean }) => void;
  reject: () => void;
  title: string;
  message: JSX.Element | string;
  hint?: JSX.Element | string;
  action: string;
}

export function useConfirmationDialog() {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [activePrompt, setActivePrompt] = useState<PromptState | null>(null);

  const onClickOk = useCallback(() => {
    if (activePrompt) {
      activePrompt.resolve({ ok: true });
      setActivePrompt(null);
    }
  }, [activePrompt, setActivePrompt]);

  const onCancel = useCallback(() => {
    if (activePrompt) {
      activePrompt.resolve({ ok: false });
      setActivePrompt(null);
    }
  }, [activePrompt, setActivePrompt]);

  const confirmationElement = useMemo(() => {
    return (
      <AlertDialog
        isOpen={!!activePrompt}
        onClose={onCancel}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{activePrompt?.title ?? "-"}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {activePrompt?.message ?? "-"}
              {activePrompt?.hint && (
                <Text mt="10px" style={{ fontSize: "14px", color: "red" }}>
                  {activePrompt?.hint}
                </Text>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancel}>
                Cancel
              </Button>
              <Button colorScheme="cherryButton" ml={3} onClick={onClickOk}>
                {activePrompt?.action ?? "Yes"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }, [activePrompt, cancelRef, onCancel, onClickOk]);

  const showConfirmationDialog = useCallback(
    (
      title: string,
      message: JSX.Element | string,
      action: string,
      hint?: string
    ): Promise<{ ok: boolean }> => {
      const promise = new Promise<{ ok: boolean }>((resolve, reject) => {
        setActivePrompt({
          resolve,
          reject,
          title,
          message,
          action,
          hint,
        });
      }).then((result) => {
        return result;
      });

      return promise;
    },
    [setActivePrompt]
  );

  return { confirmationElement, showConfirmationDialog };
}
