import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Chrome, Colorful } from "@uiw/react-color";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { CancelButton } from "../../../../components/Shared/CancelButton";
import { SaveButton } from "../../../../components/Shared/SaveButton";
import ToggleSwitch from "../../../../components/Shared/ToggleSwitch";
import { useVenue } from "../../../../context/VenueContext";
import { isEmptyStr } from "../../../../util/stringHelper";
import { useAdminApiClient } from "../../../../hooks/useApiClient";
import { useToast } from "../../../../hooks/useToast";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import { useConfirmationDialog } from "../../../../hooks/useConfirmationDialog";

const FreewayTheme = () => {
  const { venue, loadVenue } = useVenue();
  const apiClient = useAdminApiClient();
  const { showSuccessToast, showErrorToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();
  const [form, setForm] = useState<any>({
    selectedTheme: "base",
    isLightMode: false,
    primaryColor: "#c51836",
    primaryColorBg1: "#ffffff",
    primaryColorBg2: "rgba(240,240,240)",
    containerColor: "#ffffff",
    containerColorBg: "rgba(0,0,0,0.8)",
    containerColorBg2: "rgba(12,10,12,0.7)",
    font1: "Oswald",
    font2: "Poppins",
    bgUrl: "",
    venueLogoUrl: "",
    introMessage: "",
    thirdColumnHeader: "",
    thirdColumnText: "",
    thirdColumnImageUrl: "",
    ledRingSpeed: "",
    ledRingEffects: "",
    ledRingColour: "",
    ledRingBrightness: "",
  });
  const [formBuffer, setFormBuffer] = useState<any>({});
  const [venueSiblings, setVenueSiblings] = useState<any[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryString, setQueryString] = useState("");
  const intervalRef = useRef<any>();

  const isFormDirty = useMemo(() => {
    return Object.keys(form)
      .filter((k) => k !== "copyThemeToVenue")
      .find((key) => form[key] !== formBuffer[key]);
  }, [form, formBuffer]);

  console.log("isFormDirty", isFormDirty);

  const loadVenueSiblings = async () => {
    const venuesData: any = await apiClient.getAllVenues();
    console.log("venuesData", venuesData);
    const siblings = venuesData.data?.items?.filter(
      (v: any) => v._id !== venue._id
    );
    setVenueSiblings(siblings);
  };

  const updateQueryString = useCallback(() => {
    const str = Object.keys(form).map((key) => {
      const val = encodeURIComponent(form[key]);
      return `${key}=${val}`;
    });
    setQueryString(str.join("&"));
  }, [form]);

  useEffect(() => {
    clearTimeout(intervalRef.current);
    intervalRef.current = setTimeout(() => {
      updateQueryString();
    }, 2000);
    console.log("form", form);
  }, [form]);

  const updateForm = (data: any) => {
    setForm({ ...form, ...data });
  };

  const toggleTheme = useCallback(() => {
    let newFormData = {};
    if (form.isLightMode) {
      newFormData = {
        isLightMode: false,
        containerColor: "#ffffff",
        containerColorBg: "rgba(0,0,0,0.8)",
        containerColorBg2: "rgba(12,10,12,0.7)",
      };
    } else {
      newFormData = {
        isLightMode: true,
        containerColor: "#000000",
        containerColorBg: "rgba(255,255,255,0.8)",
        containerColorBg2: "rgba(245,255,245,0.7)",
      };
    }
    setForm({ ...form, ...newFormData });
  }, [form]);

  const handleUseCustomField = (value: boolean) => {
    setForm({ ...form, features: [{ customFields: value }] });
  };

  const initializeForm = async () => {
    updateForm({ ...form, ...venue.freewayTheme });
    setFormBuffer({ ...form, ...venue.freewayTheme });
  };

  const onCancel = () => {
    updateForm({ ...form, ...formBuffer });
  };

  const onCopy = useCallback(async () => {
    if (isFormDirty) {
      showErrorToast("Save current Theme before copying.");
      return;
    }
    showConfirmationDialog(
      "Confirm copy of *Theme Setup*".toUpperCase(),
      `Confirm that you wish to copy the *theme setup*.`,
      "Confirm",
      "This will override any existing settings."
    ).then(async ({ ok }) => {
      if (ok) {
        const payload = { freewayTheme: { ...form } };
        await apiClient.modifyVenue(form.copyThemeToVenue, payload);
        showSuccessToast(
          "*Theme Setup* copied successfully to the selected venue."
        );
      }
    });
  }, [isFormDirty, form]);

  const onSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    setIsSubmitting(true);
    try {
      const payload = { freewayTheme: { ...form } };
      await apiClient.modifyVenue(venue?._id, payload);
      await loadVenue(false);
      setIsSubmitting(false);
      showSuccessToast("*Theme Setup* saved successfully.");
      setFormBuffer({ ...form });
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast("Failed to update the Theme.");
      console.log(e);
    }
  };

  const renderAsterisk = () => {
    return (
      <Text
        style={{ display: "inline" }}
        size="xl"
        color="red"
        fontWeight="bold"
      >
        *
      </Text>
    );
  };

  const validateForm = () => {
    let isValid = true;
    const thirdColumnErrorText =
      "Third Column Message is incomplete and must be entered before saving Theme.";
    const ledErrorText =
      "LED Ring Functionality is incomplete and must be entered before saving Theme.";
    const fieldErrors: any = {
      venueLogoUrl: "Venue Logo must be uploaded before saving Theme.",
      introMessage: "Intro Message must be entered before saving Theme.",
      thirdColumnHeader: thirdColumnErrorText,
      thirdColumnText: thirdColumnErrorText,
      thirdColumnImageUrl: thirdColumnErrorText,
      ledRingSpeed: ledErrorText,
      ledRingEffects: ledErrorText,
      ledRingColour: ledErrorText,
      ledRingBrightness: ledErrorText,
    };
    try {
      Object.keys(fieldErrors).forEach((fieldName) => {
        const error = fieldErrors[fieldName];
        const value = form[fieldName];
        if (isEmptyStr(value)) {
          isValid = false;
          showErrorToast(error);
          throw new Error(error);
        }
      });
    } catch (e) {
      setIsFormValid(isValid);
    }

    return isValid;
  };

  const isModifiable = useCallback(() => {
    return form.selectedTheme === "base";
  }, [form]);

  useEffect(() => {
    initializeForm();
    loadVenueSiblings();
  }, [venue]);

  useEffect(() => {
    console.log("venueSiblings", venueSiblings);
  }, [venueSiblings]);

  useEffect(() => {
    // validateForm();
  }, [form]);

  return (
    <>
      <Text as="h2" className="page-section-title" mb="20px">
        THEME SETUP
      </Text>
      <Text as="h4" fontSize="16px" color="red" mb="30px">
        Required {renderAsterisk()}
      </Text>
      <Box flexDir="row" display="flex" gap={10}>
        <Box flex="1">
          {/* theme selector */}
          <Box className="theme-component">
            <FormControl maxW="400px">
              <Text
                as="h3"
                mb="10px"
                className="page-section-subtitle caps-style"
              >
                Venue Theme
              </Text>
              <Select
                className="theme-dropdown"
                value={form.selectedTheme}
                onChange={(e) => updateForm({ selectedTheme: e.target.value })}
              >
                <option value="base">Base Theme</option>
                <optgroup label="System Themes">
                  <option value="system_gold">Gold</option>
                  <option value="system_vintage">Vintage</option>
                </optgroup>
              </Select>
            </FormControl>
          </Box>
          {isModifiable() && (
            <Box width="151px" className="theme-component">
              <ToggleSwitch
                labelNode={
                  <Text as="h3" className="page-section-subtitle caps-style">
                    Dark Mode
                  </Text>
                }
                isChecked={!form.isLightMode}
                onChange={(e) => {
                  toggleTheme();
                }}
              />
            </Box>
          )}

          {/* primary color */}
          {isModifiable() && (
            <Box className="theme-component">
              <Text
                as="h3"
                mt="10px"
                className="page-section-subtitle caps-style"
              >
                Primary Color (Left Column)
              </Text>
              <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
                Applies to the background and button text.
              </Text>
              <Popover>
                <PopoverTrigger>
                  <Button
                    aria-label={form.primaryColor}
                    className="theme-color-button"
                  >
                    <Box
                      background={form.primaryColor}
                      className="theme-color-indicator"
                    ></Box>
                    <Text className="theme-color-hex">{form.primaryColor}</Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent outline="none" width="270px">
                  <PopoverArrow bg={form.primaryColor} />
                  <PopoverCloseButton color="#000" />
                  <Chrome
                    showAlpha={false}
                    color={form.primaryColor}
                    onChange={(color) =>
                      updateForm({ primaryColor: color.hex })
                    }
                  />
                </PopoverContent>
              </Popover>
            </Box>
          )}
          {/* secondary color */}
          {isModifiable() && (
            <Box className="theme-component">
              <Text
                as="h3"
                mt="10px"
                className="page-section-subtitle caps-style"
              >
                Secondary Color (Left Column)
              </Text>
              <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
                Applies to the button background, TAP TO START text, and
                DISCONNECT text.
              </Text>

              <Popover>
                <PopoverTrigger>
                  <Button
                    aria-label={form.primaryColorBg1}
                    className="theme-color-button"
                  >
                    <Box
                      background={form.primaryColorBg1}
                      className="theme-color-indicator"
                    ></Box>
                    <Text className="theme-color-hex">
                      {form.primaryColorBg1}
                    </Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent outline="none" width="270px">
                  <PopoverArrow bg={form.primaryColorBg1} />
                  <PopoverCloseButton color="#000" />
                  <Chrome
                    showAlpha={false}
                    color={form.primaryColorBg1}
                    onChange={(color) =>
                      updateForm({ primaryColorBg1: color.hex })
                    }
                  />
                </PopoverContent>
              </Popover>
            </Box>
          )}

          {/* secondary color 2 */}
          {isModifiable() && (
            <Box className="theme-component">
              <Text
                as="h3"
                mt="10px"
                className="page-section-subtitle caps-style"
              >
                Secondary Color 2 (Left Column)
              </Text>
              <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
                Applies to the gradient effect on the buttons. Keep this color
                close to the Secondary Color.
              </Text>

              <Popover>
                <PopoverTrigger>
                  <Button
                    aria-label={form.primaryColorBg2}
                    className="theme-color-button"
                  >
                    <Box
                      background={form.primaryColorBg2}
                      className="theme-color-indicator"
                    ></Box>
                    <Text className="theme-color-hex">
                      {form.primaryColorBg2}
                    </Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent outline="none" width="270px">
                  <PopoverArrow bg={form.primaryColorBg2} />
                  <PopoverCloseButton color="#000" />
                  <Chrome
                    showAlpha={false}
                    color={form.primaryColorBg2}
                    onChange={(color) =>
                      updateForm({ primaryColorBg2: color.hex })
                    }
                  />
                </PopoverContent>
              </Popover>
            </Box>
          )}

          {/* background image */}
          {isModifiable() && (
            <Box className="theme-component">
              <Text
                as="h3"
                mt="10px"
                className="page-section-subtitle caps-style"
              >
                Background Image
              </Text>
              <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
                Upload background image (1920px x 480px - jpg, png, mp4)
              </Text>
              <FileUpload
                existingUrl={form.bgUrl}
                accept="image/*,video/mp4"
                dimensionError="Please upload a background image with dimensions 1920x480px in JPG, PNG, or MP4 format only."
                allowedHeight={480}
                allowedWidth={1920}
                onComplete={(url: string) => {
                  updateForm({ bgUrl: encodeURIComponent(url) });
                }}
              />
            </Box>
          )}
          {/* venue logo */}
          <Box className="theme-component">
            <Text
              as="h3"
              mt="10px"
              className="page-section-subtitle caps-style"
            >
              Venue Logo {renderAsterisk()}
            </Text>
            <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
              Upload logo (jpg, png)
            </Text>
            <FileUpload
              maxFileSize={300}
              dimensionError="Invalid file type or size. Please upload a JPG or PNG file with max dimensions 400x250px and max file size 300kb."
              allowedHeight={250}
              allowedWidth={400}
              existingUrl={form.venueLogoUrl}
              onComplete={(url: string) => {
                updateForm({ venueLogoUrl: encodeURIComponent(url) });
              }}
            />
          </Box>

          {/* <FormControl w="100%" mb={4}>
            <Text className="page-section-content">Primary Font:</Text>
            <Select
              value={form.font_1}
              onChange={(e) => updateForm({ font_1: e.target.value })}
            >
              <option value="Oswald">Oswald</option>
              <option value="Poppins">Poppins</option>
            </Select>
          </FormControl>

          <FormControl w="100%" mb={4}>
            <Text className="page-section-content">Secondary Font:</Text>
            <Select
              value={form.font_2}
              onChange={(e) => updateForm({ font_2: e.target.value })}
            >
              <option value="Oswald">Oswald</option>
              <option value="Poppins">Poppins</option>
            </Select>
          </FormControl>

          <FormControl w="100%" mb={4}>
            <Text mb={2} className="page-section-content">
              Background Image (1920x480px only):
            </Text>
            <FileUpload
              allowedHeight={480}
              allowedWidth={1920}
              onComplete={(url: string) => {
                updateForm({ bgUrl: encodeURIComponent(url) });
              }}
            />
          </FormControl> */}

          {/* intro message */}
          <Box className="theme-component">
            <FormControl maxW="400px">
              <Text
                as="h3"
                mb="10px"
                className="page-section-subtitle caps-style"
              >
                Intro Message {renderAsterisk()}
              </Text>
              <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
                Intro message for the middle column eg: Welcome to Venue Name
              </Text>
              <Box>
                <Input
                  maxLength={50}
                  type="text"
                  className="theme-input"
                  defaultValue={form.introMessage}
                  onChange={(e) => updateForm({ introMessage: e.target.value })}
                />
              </Box>
            </FormControl>
          </Box>

          {/* THIRD COLUMN CONTAINER */}
          <Box className="theme-component-container" maxW="600px">
            <Box className="theme-component">
              <Text
                as="h3"
                mb="10px"
                className="page-section-subtitle caps-style"
              >
                THIRD COLUMN MESSAGE {renderAsterisk()}
              </Text>
              <Text mt="10px" mb="25px" fontSize="xs" className="body-text">
                Message displayed for non-members or when no advertising is
                active.
              </Text>

              <Box mb="20px">
                {/* thirdColumnHeader */}
                <FormControl>
                  <Text
                    as="h3"
                    mb="10px"
                    className="page-section-subtitle caps-style"
                  >
                    HEADER
                  </Text>
                  <Box>
                    <Input
                      type="text"
                      className="theme-input"
                      defaultValue={form.thirdColumnHeader}
                      onChange={(e) =>
                        updateForm({ thirdColumnHeader: e.target.value })
                      }
                    />
                  </Box>
                </FormControl>
              </Box>

              <Box mb="20px">
                {/* thirdColumnText */}
                <FormControl>
                  <Text
                    as="h3"
                    mb="10px"
                    className="page-section-subtitle caps-style"
                  >
                    TEXT
                  </Text>
                  <Box>
                    <Textarea
                      className="theme-input"
                      defaultValue={form.thirdColumnText}
                      onChange={(e) =>
                        updateForm({ thirdColumnText: e.target.value })
                      }
                    />
                  </Box>
                </FormControl>
              </Box>

              {/* thirdColumnImageUrl */}
              <Box className="theme-component">
                <Text
                  as="h3"
                  mt="10px"
                  className="page-section-subtitle caps-style"
                >
                  IMAGE
                </Text>
                <FileUpload
                  existingUrl={form.thirdColumnImageUrl}
                  onComplete={(url: string) => {
                    updateForm({
                      thirdColumnImageUrl: encodeURIComponent(url),
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* LED RING CONTAINER */}
          <Box className="theme-component-container" maxW="600px" mt="30px">
            <Box className="theme-component">
              <Text
                as="h3"
                mb="25px"
                className="page-section-subtitle caps-style"
              >
                LED RING FUNCTIONALITY {renderAsterisk()}
              </Text>

              <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                {/* ledRingColour */}
                <GridItem w="100%">
                  <Box mb="10px">
                    {/* ledRingColour */}
                    <FormControl>
                      <Text
                        as="h3"
                        mb="10px"
                        className="page-section-subtitle caps-style"
                      >
                        COLOUR
                      </Text>
                      <Box>
                        <Select
                          className="theme-dropdown"
                          value={form.ledRingColour}
                          onChange={(e) =>
                            updateForm({ ledRingColour: e.target.value })
                          }
                        >
                          <option value=""></option>
                          <option value="Red">Red</option>
                          <option value="Green">Green</option>
                          <option value="Blue">Blue</option>
                          <option value="White">White</option>
                          <option value="Rainbow">Rainbow</option>
                        </Select>
                      </Box>
                    </FormControl>
                  </Box>
                </GridItem>

                {/* ledRingEffects */}
                <GridItem w="100%">
                  <Box mb="10px">
                    {/* ledRingEffects */}
                    <FormControl>
                      <Text
                        as="h3"
                        mb="10px"
                        className="page-section-subtitle caps-style"
                      >
                        EFFECTS
                      </Text>
                      <Box>
                        <Select
                          className="theme-dropdown"
                          value={form.ledRingEffects}
                          onChange={(e) =>
                            updateForm({ ledRingEffects: e.target.value })
                          }
                        >
                          <option value=""></option>
                          <option value="Static">Static</option>
                          <option value="Fade">Fade</option>
                          <option value="Flash">Flash</option>
                          <option value="Follow">Follow</option>
                        </Select>
                      </Box>
                    </FormControl>
                  </Box>
                </GridItem>

                {/* ledRingSpeed */}
                <GridItem w="100%">
                  <Box mb="10px">
                    {/* ledRingSpeed */}
                    <FormControl>
                      <Text
                        as="h3"
                        mb="10px"
                        className="page-section-subtitle caps-style"
                      >
                        SPEED
                      </Text>
                      <Box>
                        <Select
                          className="theme-dropdown"
                          value={form.ledRingSpeed}
                          onChange={(e) =>
                            updateForm({ ledRingSpeed: e.target.value })
                          }
                        >
                          <option value=""></option>
                          <option value="Slow">Slow</option>
                          <option value="Medium">Medium</option>
                          <option value="Fast">Fast</option>
                        </Select>
                      </Box>
                    </FormControl>
                  </Box>
                </GridItem>

                {/* ledRingBrightness */}
                <GridItem w="100%">
                  <Box mb="10px">
                    {/* ledRingBrightness */}
                    <FormControl>
                      <Text
                        as="h3"
                        mb="10px"
                        className="page-section-subtitle caps-style"
                      >
                        SPEED
                      </Text>
                      <Box>
                        <Select
                          className="theme-dropdown"
                          value={form.ledRingBrightness}
                          onChange={(e) =>
                            updateForm({ ledRingBrightness: e.target.value })
                          }
                        >
                          <option value=""></option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </Select>
                      </Box>
                    </FormControl>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </Box>

          {/* COPY THEME */}
          {venueSiblings.length > 0 && isModifiable() && (
            <Box className="theme-component" mt="30px" maxW="600px">
              <FormControl>
                <Text
                  as="h3"
                  mb="10px"
                  className="page-section-subtitle caps-style"
                >
                  Copy Theme to Another Venue
                </Text>
                <Text mt="10px" mb="10px" fontSize="xs" className="body-text">
                  Copy the theme style to another venue
                </Text>
                <Box flexDir="row" display="flex" gap={5}>
                  <Select
                    flex={1}
                    className="theme-dropdown"
                    onChange={(e) =>
                      updateForm({ copyThemeToVenue: e.target.value })
                    }
                  >
                    <option value=""></option>
                    {venueSiblings.map((v) => (
                      <option value={v._id}>{v.title}</option>
                    ))}
                  </Select>
                  <SaveButton
                    width="150px"
                    title="COPY THEME"
                    isLoading={isSubmitting}
                    disable={!form.copyThemeToVenue}
                    onClick={() => onCopy()}
                  />
                </Box>
              </FormControl>
            </Box>
          )}
        </Box>

        <Box w="768px" position="fixed" right="65px" className="only-desktop">
          <Text as="h3" mb="10px" mt="10px" className="page-section-subtitle">
            PREVIEW:
          </Text>
          <Box
            style={{
              transform: "scale(0.4)",
              transformOrigin: "left top",
              height: "203px",
            }}
          >
            {queryString && (
              <iframe
                style={{ borderRadius: "20px" }}
                src={FREEWAY_URL.concat(`?${queryString}`)}
                width="1920px"
                height="480px"
              />
            )}
          </Box>

          <Text
            fontSize="sm"
            mb="10px"
            mt="10px"
            className="body-text"
            textAlign="center"
          >
            Open in{" "}
            <span style={{ color: "red" }}>
              {queryString && (
                <a
                  style={{ color: "red" }}
                  target="_blank"
                  href={FREEWAY_URL.concat(`?${queryString}`)}
                >
                  new window
                </a>
              )}
            </span>
          </Text>
        </Box>
      </Box>
      <Divider mt={5} />
      <Box mt="60px">
        <HStack spacing={4}>
          <SaveButton
            isLoading={isSubmitting}
            disable={!isFormDirty || isSubmitting}
            onClick={onSubmit}
          />
          <CancelButton onClick={onCancel} />
        </HStack>
      </Box>
      {confirmationElement}
    </>
  );
};

export default FreewayTheme;
