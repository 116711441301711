import {
  Box,
  HStack,
  Spacer,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import RoundedBackButton from "../../components/ReoundedBackButton/RoundedBackButton";
import { useBusiness } from "../../context/BusinessContext";
import { useVenue } from "../../context/VenueContext";
import BusinessUtil from "../../util/BusinessUtil";
import DateUtil from "../../util/DateUtil";
import VenueDetailSection from "./Section/Details";
import VenueSettingSection from "./Section/Settings";
import VenueConfig from "./Section/VenueConfig";
import FreewayConfiguration from "./Section/FreewayConfiguration";

export const VenueDetail = () => {
  const navigate = useNavigate();
  const { venue, isLoading } = useVenue();
  const contextBusiness = useBusiness();

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, ""));
  };

  if (isLoading) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading customStyle={{ height: "164px" }}>
        <HStack mt={-6}>
          <RoundedBackButton onClick={onCancel} />
          <Spacer w="8px" />
          <VStack alignItems="start">
            <Box>
              <PageHeading.Title>{venue?.title || "N/A"}</PageHeading.Title>
            </Box>
            <HStack spacing="10" mt={0}>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                id: {venue?.srcVenue?.id ?? "N/A"}
              </Text>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                created:{" "}
                {DateUtil.getFormattedDateWithoutTimeZone(
                  venue?.createdAt || "",
                  "dd/MM/yyyy"
                )}
              </Text>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                edited:{" "}
                {DateUtil.getFormattedDateWithoutTimeZone(
                  venue?.updatedAt || "",
                  "dd/MM/yyyy"
                )}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </PageHeading>

      <PageContent bgColor="#f0f0f0">
        <Box width="100%" display="flex" flexDirection="row" mt="-80px">
          <Tabs
            isLazy
            position="relative"
            variant="unstyled"
            width="100%"
            id="main-tabs"
            defaultIndex={0}
          >
            <TabList>
              <Tab
                className="tab-button"
                _focus={{ outline: "none" }}
                _selected={{ opacity: 1 }}
              >
                DETAILS
              </Tab>
              <Tab
                className="tab-button"
                _focus={{ outline: "none" }}
                _selected={{ opacity: 1 }}
              >
                SETTINGS
              </Tab>
              <Tab
                className="tab-button"
                _focus={{ outline: "none" }}
                _selected={{ opacity: 1 }}
              >
                CONFIGURATIONS
              </Tab>
              <Tab
                className="tab-button"
                _focus={{ outline: "none" }}
                _selected={{ opacity: 1 }}
              >
                FREEWAY
              </Tab>
            </TabList>

            <TabIndicator
              mt="-1.5px"
              height="4px"
              bg="#9A132A"
              borderRadius="1px"
              width="100%"
            />
            <TabPanels>
              <TabPanel padding={0} pt="40px">
                <VenueDetailSection />
              </TabPanel>
              <TabPanel padding={0} pt="40px">
                <VenueSettingSection />
              </TabPanel>
              <TabPanel padding={0} pt="40px">
                <VenueConfig />
              </TabPanel>
              <TabPanel padding={0} pt="40px">
                <FreewayConfiguration />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </PageContent>
    </>
  );
};
